import { template as template_fd779b42913b4d518ad8826d845084c6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fd779b42913b4d518ad8826d845084c6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
