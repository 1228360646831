import { template as template_19d20313ba5f43c3bbc5219c72662061 } from "@ember/template-compiler";
const FKText = template_19d20313ba5f43c3bbc5219c72662061(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
